import axios from 'axios';

import TaskStatus from '@/models/task-status.model';
import Toast from '@/toast';

export default class TaskStatusService {
	public static async get(): Promise<TaskStatus[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/task-statuses`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar statusar til uppgávurnar`, error);

				Toast.error('Tað bar ikki til at heinta støðurnar til uppgávurnar');

				return false;
			});
	}
}
